<template>
    <v-row>
        <v-col md="4" cols="12">
            <base-card class="h-full">
                <v-row>
                    <v-col cols="12" class="text-center">
                        <v-avatar size="90" class="mt-2">
                            <v-img src="/assets/gaa/teams/icons/CORK.WEBP" />
                        </v-avatar>
                        <div class="card-title ma-0 text-h5">Cork</div>
                        <p class="text--disabled mb-1">Munster</p>
                        <div class="d-flex align-center justify-center">
                            <v-icon class="text-primary body-1 mr-1">mdi-map-marker</v-icon>
                            <span class="text-primary text-12">Cork City, Cork</span>
                        </div>
                    </v-col>
                    <v-col cols="12" class="mb-0">
                        <v-card-subtitle class="py-0">
                            <h5 class="">
                                Basic Info
                            </h5>
                        </v-card-subtitle>
                        <v-card-text class="pb-0">
                            <v-row>
                                <v-col cols="5" class="py-1">
                                    <b>Nickname</b>
                                </v-col>
                                <v-col cols="2" />
                                <v-col cols="5" class="py-1">
                                    The Rebels
                                </v-col>

                                <v-col cols="5" class="py-1">
                                    <b>Colours</b>
                                </v-col>
                                <v-col cols="2" />
                                <v-col cols="5" class="py-1">
                                    Red and White
                                </v-col>

                                <v-col cols="5" class="py-1">
                                    <b>Stadium</b>
                                </v-col>
                                <v-col cols="2" />
                                <v-col cols="5" class="py-1">
                                    Pairc Úi Caomih
                                </v-col>
                                <v-col cols="1" />
                                <v-col cols="5" class="py-0 text-caption">
                                    <b>Capacity</b>
                                </v-col>
                                <v-col cols="1" />
                                <v-col cols="5" class="py-0 text-caption">
                                    45,000
                                </v-col>

                                <v-col cols="1" />
                                <v-col cols="5" class="py-0 text-caption">
                                    <b>Location</b>
                                </v-col>
                                <v-col cols="1" />
                                <v-col cols="5" class="py-0 text-caption">
                                    Pairc Ui Chaoimh, The Marina, Cork
                                </v-col>

                                <v-col cols="1" />
                                <v-col cols="5" class="py-0 text-caption">
                                    <b>Sponsor</b>
                                </v-col>
                                <v-col cols="1" />
                                <v-col cols="5" class="py-0 text-caption">
                                    SuperValu
                                </v-col>

                                <v-col cols="5" class="py-1">
                                    <b>Bainisteoir </b>
                                </v-col>
                                <v-col cols="2" />
                                <v-col cols="5" class="py-1">
                                    Pat Ryan
                                </v-col>

                                <v-col cols="5" class="py-1">
                                    <b>Ranking </b>
                                </v-col>
                                <v-col cols="2" />
                                <v-col cols="5" class="py-1">
                                    3rd
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-col>
                </v-row>
            </base-card>
        </v-col>
        <v-col md="8" cols="12" :class="$vuetify.breakpoint.xsOnly ? 'pt-0' : ''">
            <v-row>
                <v-col sm="6" cols="12" :class="$vuetify.breakpoint.xsOnly ? 'pt-0' : 'py-0'">
                    <base-card class="h-full">
                        <v-row>
                            <v-col cols="12" class="text-center">
                                <div class="card-title ma-0 text-h6">Honours</div>
                            </v-col>
                            <v-col cols="12" class="my-0 py-0">
                                <v-card-text class="py-0">
                                    <v-row>
                                        <v-col cols="12" class="py-1">
                                            <v-divider />
                                            <v-data-table dense
                                                :headers="[{ text: 'Competition', value: 'name', sortable: false }, { text: 'Total Titles', value: 'total', sortable: false }, { text: 'Rank All Time', value: 'rank', sortable: false }]"
                                                :items="[{ 'name': 'All Ireland', 'total': 30, 'rank': '2nd' }, { 'name': 'Munster', 'total': 54, 'rank': '1st' }]"
                                                hide-default-footer mobile-breakpoint="0">
                                            </v-data-table>
                                            <v-divider />
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions>
                                    <v-row>
                                        <v-col style="justify-content: center; display: flex;">
                                            <v-btn plain>View Details</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                            </v-col>
                        </v-row>
                    </base-card>
                </v-col>
                <v-col sm="6" cols="12" class="py-0">
                    <base-card class="h-full">
                        <v-row>
                            <v-col cols="12" class="text-center">
                                <div class="card-title ma-0 text-h6">Standings</div>
                            </v-col>
                            <v-col cols="12" class="my-0 py-0">
                                <v-card-text class="py-0">
                                    <v-row>
                                        <v-col cols="12" class="py-1">
                                            <v-divider />
                                            <v-data-table dense :headers="[
                                                    { text: 'Pos', value: 'position', sortable: false },
                                                    { text: 'Team', value: 'team', sortable: false },
                                                    { text: '+/-', value: 'diff', sortable: false },
                                                    { text: 'Points', value: 'points', sortable: false }
                                                ]" :items="[
                                                    { 'position': '1', 'team': 'Clare', 'diff': '+8', 'points': 6 },
                                                    { 'position': '2', 'team': 'Limerick', 'diff': '+3', 'points': 6 },
                                                    { 'position': '3', 'team': 'Cork', 'diff': '+1', 'points': 5 },
                                                    { 'position': '4', 'team': 'Waterford', 'diff': '-4', 'points': 4 },
                                                    { 'position': '5', 'team': 'Tipperary', 'diff': '-12', 'points': 0 }
                                                ]" hide-default-footer mobile-breakpoint="0">
                                                <template v-slot:footer>
                                                    <div style="justify-content: center; display: flex;">
                                                        <v-btn plain flat @click="handleButtonClick">
                                                            View Details
                                                        </v-btn>
                                                    </div>
                                                </template>
                                            </v-data-table>
                                            <v-divider />
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </base-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="pb-0">
                    <base-card class="h-full">
                        <v-row>
                            <v-col cols="12" class="my-0 py-0">
                                <v-card-text class="py-0">
                                    <v-tabs v-model="tab" centered ref="tabsItems">

                                        <v-tab>
                                            Results
                                        </v-tab>

                                        <v-tab>
                                            Fixtures
                                        </v-tab>

                                    </v-tabs>

                                    <v-tabs-items v-model="tab" touchless>
                                        <v-tab-item :key="0">
                                            <div>
                                                <v-row>
                                                    <v-col>
                                                        <v-data-table dense :headers="resultHeaders" :items="results"
                                                            hide-default-footer mobile-breakpoint="0">
                                                            <template v-slot:item.opponentLogo="{ item }">
                                                                <v-img
                                                                    :src="`/assets/gaa/teams/icons/${item.opponentLogo}.WEBP`"
                                                                    :alt="item.opponentLogo" contain max-width="30"
                                                                    class="img_border"></v-img>
                                                            </template>
                                                            <template v-slot:item.opponent="{ item }">
                                                                <h6 class="ma-0 font-weight-medium">
                                                                    {{ item.opponent }}
                                                                </h6>
                                                            </template>

                                                            <template v-slot:item.result="{ item }">
                                                                <span :style="calculateResultColour(item)">{{
                                                    calculateResultText(item) }}</span>
                                                            </template>
                                                            <template v-slot:footer>
                                                                <div style="justify-content: center; display: flex;">
                                                                    <v-btn plain flat @click="handleButtonClick">
                                                                        View Details
                                                                    </v-btn>
                                                                </div>
                                                            </template>
                                                        </v-data-table>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </v-tab-item>
                                        <v-tab-item :key="2">
                                            <div>
                                                <v-row>
                                                    <v-col>
                                                        <v-data-table dense :headers="fixtureHeaders" :items="fixtures"
                                                            hide-default-footer class="elevation-1"
                                                            mobile-breakpoint="0">

                                                            <template v-slot:item.opponentLogo="{ item }">
                                                                <v-img
                                                                    :src="`/assets/gaa/teams/icons/${item.opponentLogo}.WEBP`"
                                                                    :alt="item.opponentLogo" contain max-width="30"
                                                                    class="img_border"></v-img>
                                                            </template>
                                                            <template v-slot:item.opponent="{ item }">
                                                                <h6 class="ma-0 font-weight-medium">
                                                                    {{ item.opponent }}
                                                                </h6>
                                                            </template>

                                                            <template v-slot:item.date="{ item }">
                                                                <div>{{ item.time }} {{ item.date }}</div>
                                                            </template>

                                                            <template v-slot:item.coverage="{ item }">
                                                                <div>
                                                                    <v-img contain
                                                                        :max-width="$vuetify.breakpoint.xsOnly ? 50 : 80"
                                                                        :src="`/assets/tv/logo/${item.coverage}.jpg`"
                                                                        :alt="item.coverage" />
                                                                </div>
                                                            </template>
                                                            <template v-slot:footer>
                                                                <div style="justify-content: center; display: flex;">
                                                                    <v-btn plain flat @click="handleButtonClick">
                                                                        View Details
                                                                    </v-btn>
                                                                </div>
                                                            </template>
                                                        </v-data-table>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </v-tab-item>
                                    </v-tabs-items>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </base-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    data() {
        return {
            tab: 0,
            headers: [
                {
                    text: '',
                    align: 'start',
                    sortable: false,
                    value: 'opponentLogo',
                },
                { text: 'Opponent', value: 'opponent' },
                { text: 'Result', value: 'result' },
                { text: 'Date', value: 'date' },
                { text: 'Venue', value: 'venue' },
                { text: 'Competition', value: 'competition' },
                { text: 'Round', value: 'round' },
                { text: 'TV', value: 'coverage' }
            ],
            results: [
                {
                    "opponentLogo": "TIPP",
                    "opponent": "Tipperary",
                    "result": "AWAY_WIN",
                    "homeGoals": 2,
                    "homePoints": 19,
                    "awayGoals": 1,
                    "awayPoints": 15,
                    "date": "3/5/24",
                    "venue": "Thurles",
                    "competition": "Munster",
                    "round": "Round Robin 4"
                },
                {
                    "opponentLogo": "LIM",
                    "opponent": "Limerick",
                    "result": "AWAY_WIN",
                    "homeGoals": 3,
                    "homePoints": 22,
                    "awayGoals": 2,
                    "awayPoints": 23,
                    "date": "28/4/24",
                    "venue": "Cork",
                    "competition": "Munster",
                    "round": "Round Robin 3"
                },
                {
                    "opponentLogo": "CLA",
                    "opponent": "Clare",
                    "result": "HOME_WIN",
                    "homeGoals": 3,
                    "homePoints": 18,
                    "awayGoals": 3,
                    "awayPoints": 17,
                    "date": "21/4/24",
                    "venue": "Ennis",
                    "competition": "Munster",
                    "round": "Round Robin 2"
                },
                {
                    "opponentLogo": "WAT",
                    "opponent": "Waterford",
                    "result": "HOME_WIN",
                    "homeGoals": 1,
                    "homePoints": 23,
                    "awayGoals": 0,
                    "awayPoints": 24,
                    "date": "14/4/24",
                    "venue": "Waterford",
                    "competition": "Munster",
                    "round": "Round Robin 1"
                }],
            fixtures: [
                {
                    "opponentLogo": "OFF",
                    "opponent": "Offaly",
                    "result": "HOME_WIN",
                    "date": "10/5/24",
                    "time": "15:00",
                    "venue": "Tullamore",
                    "competition": "All Ireland",
                    "round": "Provision QF",
                    "coverage": "gaago"
                }, {}, {}, {}, {}]
        }
    },
    methods: {
        calculateResultColour(item) {
            if (item.result == 'HOME_WIN') {
                return 'color: red';
            } else if (item.result == 'AWAY_WIN') {
                return 'color: green';
            } else {
                return '';
            }
        },
        calculateResultText(item) {
            if (item.homeTeam && item.homeTeam.id == this.overallTeam.id) {
                return `${item.homeGoals}-${item.homePoints} ${item.awayGoals}-${item.awayPoints}`
            } else {
                return `${item.awayGoals}-${item.awayPoints} ${item.homeGoals}-${item.homePoints}`
            }
        },
    },
    computed: {
        resultHeaders() {
            if (this.$vuetify.breakpoint.xsOnly) {
                let activeHeaders = this.headers.filter(
                    it => (it.value != "coverage" & it.value != 'date' & it.value != 'venue' & it.value != 'competition')
                );
                return activeHeaders;
            }
           return this.headers.filter(it => (it.value != "coverage"))
        },
        fixtureHeaders() {
            if (this.$vuetify.breakpoint.xsOnly) {
                let activeHeaders = this.headers.filter(
                    it => (it.value != "result" & it.value != 'venue' & it.value != 'competition' & it.value != 'round')
                );
                return activeHeaders;
            }
            return this.headers.filter(it => (it.value != "result"))
        }
    }
}
</script>

<style scoped>
    .img_border{
        border-style: solid;
        border-width: 2px;
    }
</style>